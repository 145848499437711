import nlp from "compromise";
import chrono from "chrono-node";

/**
 * Converts a sentence into a task JSON object.
 * @param {string} sentence - The input sentence describing the task.
 * @param {string} ownerId - The owner ID of the task.
 * @returns {Object} The task object with all required fields.
 */
export const convertSentenceToTaskObject = (sentence, ownerId) => {
  // Use Compromise NLP to parse the sentence into structured content
  const doc = nlp(sentence);

  // Extract the title as the first main sentence
  const title = doc.sentences().first().out("text");

  // Smart Description Handling
  let description = "";

  // Break the sentence into actionable items (using basic sentence splitting)
  const actionItems = doc.sentences().out("array").slice(1);

  // Convert each action item into a Markdown bullet point
  description = actionItems
    .map((item) => `- ${item}`)
    .join("\n")
    .trim();

  // Convert links to Markdown format
  description = description.replace(
    /(https?:\/\/[^\s]+)/g, // Regex to match URLs
    (url) => `[${url}](${url})`
  );

  // Extract urgency based on keywords
  let urgency = 2; // Default is medium urgency

  if (doc.has("ASAP") || doc.has("immediately") || doc.has("urgent")) {
    urgency = 0; // Highest urgency (0)
  } else if (doc.has("high")) {
    urgency = 1;
  } else if (doc.has("low")) {
    urgency = 3;
  }

  // Determine expiration date using Chrono, focusing on keywords like "Friday"
  let expirationDate = chrono.parseDate(sentence);
  if (!expirationDate && doc.has("Friday")) {
    // If no specific date was parsed but "Friday" is mentioned, set it to the next Friday
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 is Sunday, 6 is Saturday
    const daysUntilFriday = (5 - dayOfWeek + 7) % 7 || 7; // Days until next Friday
    expirationDate = new Date(now);
    expirationDate.setDate(now.getDate() + daysUntilFriday);
  }

  // Extract tags using Compromise topics/entities (refined by looking for known tag patterns)
  const extractedTags = doc.topics().out("array");
  let tags = extractedTags.length ? extractedTags : [];

  // Check for explicit "tags: " patterns
  const tagMatch = sentence.match(/tags?:\s*(.*?)(?=[.!?]|$)/i);
  if (tagMatch) {
    tags = tagMatch[1].split(",").map((tag) => tag.trim());
  }

  // Construct the task object
  return {
    title,
    description, // The description now handles Markdown formatting
    urgency,
    tags,
    createdAt: new Date(),
    updatedAt: new Date(),
    expirationDate: expirationDate || null,
    isActive: true,
    isClosed: false,
    hasReminder: false,
    lastNotifiedDate: null,
    snoozedDate: null,
    owner: ownerId, // You can pass the ownerId when calling this function
    sharedWith: [],
  };
};
