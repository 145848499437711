import React, { useState } from "react";
import styled from "styled-components";
import axios from "./api/axiosConfig";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import HorizontalTaskList from "./components/TaskList/HorizontalTaskList";
import TaskBoard from "./components/TaskList/TaskBoard";
import TaskList from "./components/TaskList/TaskList";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TaskCreationModal from "./components/TaskCreationModal";
import { ClipLoader } from "react-spinners";
import MagicLinkInput from "./components/MagicLinkInput"; // Import the Login component
import MagicLinkVerify from "./components/MagicLinkVerify"; // Import the FinishSignUp component
import ProtectedRoute from "./ProtectedRoute"; // Import the ProtectedRoute component

function getRandomDatesOverNextMonth(count = 4) {
  const dates = [];
  const today = new Date();
  const oneMonthFromNow = new Date(today);
  oneMonthFromNow.setMonth(today.getMonth() + 1);
  const randomTime =
    today.getTime() +
    Math.random() * (oneMonthFromNow.getTime() - today.getTime());
  return new Date(randomTime);
}

const generateTasks = (startId, status) => {
  return Array.from({ length: 30 }, (_, index) => {
    const id = startId + index;
    return {
      id: id,
      title: `Task ${id}`,
      description: `### Description for Task ${id}\n\nThis is a detailed description for Task ${id}.`,
      createdAt: new Date().toLocaleString(),
      updatedAt: new Date().toLocaleString(),
      expirationDate: getRandomDatesOverNextMonth(),
      status: status,
      urgency: Math.floor(Math.random() * 5),
      tags: ["Sample", "Task", `Tag${id % 5}`],
    };
  });
};

const initialHorizontalTasks = generateTasks(1, "In Progress").slice(0, 6);
const initialVerticalTasks = generateTasks(16, "Backlog").slice(0, 15);

const AppContainer = styled.div`
  padding: 20px;
  position: relative;
`;

const HorizontalSection = styled.div`
  width: 98%;
  max-width: 100%;
  min-height: 150px;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
`;

const CreateTaskButton = styled.button`
  position: absolute;
  top: 22px;
  right: 22px;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #0056b3;
  }
`;

function App() {
  const [horizontalTasks, setHorizontalTasks] = useState(
    initialHorizontalTasks
  );
  const [verticalTasks, setVerticalTasks] = useState(initialVerticalTasks);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Track loading state

  const saveTaskArrangement = async () => {
    setLoading(true); // Show spinner when the API call starts
    const allTasks = [...horizontalTasks, ...verticalTasks];
    try {
      await axios.put("/tasks/reorder", { tasks: allTasks });
    } catch (error) {
      console.error("Failed to save task arrangement:", error);
    } finally {
      setTimeout(() => {
        setLoading(false); // Hide spinner after the API call ends
      }, 1000);
    }
  };

  const handleUpdateTask = async (taskId, updatedData) => {
    setLoading(true); // Show spinner
    const updateTasks = (tasks) =>
      tasks.map((task) =>
        task.id === taskId
          ? { ...task, ...updatedData, updatedAt: new Date().toLocaleString() }
          : task
      );

    setHorizontalTasks(updateTasks(horizontalTasks));
    setVerticalTasks(updateTasks(verticalTasks));

    try {
      await axios.put(`/tasks/${taskId}`, updatedData);
    } catch (error) {
      console.error("Failed to update task:", error);
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  const handleCreateTask = async (newTask) => {
    console.log("SAVNG");
    setLoading(true); // Show spinner
    try {
      // const { data } = await axios.post("/tasks", newTask);
      const data = { id: Math.floor(Math.random() * 1000) }; // Mock response
      newTask = { ...newTask, id: data.id }; // Assuming the server returns a unique ID

      // if (horizontalTasks.length < 6) {
      //   setHorizontalTasks([...horizontalTasks, newTask]);
      // } else {
      setVerticalTasks([newTask, ...verticalTasks]);
      // }
    } catch (error) {
      console.error("Failed to create task:", error);
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  const handleDeleteTask = async (taskId) => {
    setLoading(true); // Show spinner
    try {
      await axios.delete(`/tasks/${taskId}`);
      setHorizontalTasks((prevTasks) =>
        prevTasks.filter((task) => task.id !== taskId)
      );
      setVerticalTasks((prevTasks) =>
        prevTasks.filter((task) => task.id !== taskId)
      );
    } catch (error) {
      console.error("Failed to delete task:", error);
    } finally {
      setLoading(false); // Hide spinner
    }
  };
  const moveTask = (fromIndex, toIndex, fromList, toList) => {
    console.log("MOVING");
    let taskToMove;

    if (fromList === "horizontal") {
      console.log("FROM HORIZONTAL");

      taskToMove = horizontalTasks[fromIndex];
      setHorizontalTasks((prevTasks) => {
        const updatedTasks = [...prevTasks];
        updatedTasks.splice(fromIndex, 1);
        return updatedTasks;
      });
    } else {
      taskToMove = verticalTasks[fromIndex];
      setVerticalTasks((prevTasks) => {
        const updatedTasks = [...prevTasks];
        updatedTasks.splice(fromIndex, 1);
        return updatedTasks;
      });
    }

    if (toList === "horizontal") {
      console.log("TO HORIZONTAL");

      if (horizontalTasks.length < 6) {
        console.log("LESS THAN 6");

        setHorizontalTasks((prevTasks) => {
          const updatedTasks = [...prevTasks];
          updatedTasks.splice(
            Math.min(toIndex, horizontalTasks.length),
            0,
            taskToMove
          );
          return updatedTasks;
        });
      } else {
        console.log("BREAKS HORIZONTAL");

        return;
        // setVerticalTasks((prevTasks) => [...prevTasks, taskToMove]);
      }
    } else {
      setVerticalTasks((prevTasks) => {
        const updatedTasks = [...prevTasks];
        updatedTasks.splice(
          Math.min(toIndex, verticalTasks.length),
          0,
          taskToMove
        );
        return updatedTasks;
      });
    }

    saveTaskArrangement();
  };

  const handleDropTask = (item, destinationList) => {
    const { listId } = item;
    if (listId === destinationList) return;

    const destinationIndex =
      destinationList === "horizontal"
        ? horizontalTasks.length
        : verticalTasks.length;

    moveTask(item.index, destinationIndex, listId, destinationList);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
        <Routes>
          {/* Authentication Routes */}
          <Route path="/login" element={<MagicLinkInput />} />
          <Route path="/verify" element={<MagicLinkVerify />} />
          {/* Task Management Route */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <AppContainer>
                  <CreateTaskButton onClick={() => setIsModalOpen(true)}>
                    +
                  </CreateTaskButton>
                  <SpinnerContainer>
                    <ClipLoader color={"#007bff"} loading={loading} size={60} />
                  </SpinnerContainer>
                  {isModalOpen && (
                    <TaskCreationModal
                      onClose={() => setIsModalOpen(false)}
                      onCreateTask={handleCreateTask}
                    />
                  )}
                  <HorizontalSection>
                    <HorizontalTaskList
                      onDrop={(item) => {
                        if (horizontalTasks.length >= 6) {
                          handleDropTask(item, "horizontal");
                        }
                      }}
                    >
                      <TaskList
                        tasks={horizontalTasks}
                        listId="horizontal"
                        onUpdateTask={handleUpdateTask}
                        moveTask={moveTask}
                        onDeleteTask={handleDeleteTask}
                      />
                    </HorizontalTaskList>
                  </HorizontalSection>
                  <TaskBoard
                    onDrop={(item) => handleDropTask(item, "vertical")}
                  >
                    <TaskList
                      tasks={verticalTasks}
                      listId="vertical"
                      onUpdateTask={handleUpdateTask}
                      moveTask={moveTask}
                      onDeleteTask={handleDeleteTask}
                    />
                  </TaskBoard>
                </AppContainer>
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </DndProvider>
  );
}
export default App;
