import React, { useState } from "react";
import { convertSentenceToTaskObject } from "../utils/convertSentenceToTaskObject"; // Assuming you have this utility function

function TaskConverterInput({ onConvert }) {
  const [inputText, setInputText] = useState("");

  const handleConvert = () => {
    const ownerId = 'ObjectId("64e7b76fcbdfb1001a748e72")'; // Replace this with dynamic owner ID if needed
    const parsedTask = convertSentenceToTaskObject(inputText, ownerId);
    onConvert(parsedTask);
  };

  return (
    <div>
      <textarea
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Smart sentence to convert to task"
        style={{
          width: "95%",
          height: "50px",
          padding: "10px",
          marginBottom: "10px",
        }}
      />
      <button onClick={handleConvert} style={{ padding: "10px 20px" }}>
        Convert to Task
      </button>
    </div>
  );
}

export default TaskConverterInput;
