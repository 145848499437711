import axios from "axios";
// Check if prod or not

const baseURI =
  process.env.REACT_APP_TODOQUEUE_CLIENT_BASE_URL ||
  "https://todoqueue-server-2f08cc1055aa.herokuapp.com" ||
  "http://localhost:5000";
console.log("--baseURI", baseURI);
const axiosInstance = axios.create({
  baseURL: `${baseURI}/api`,
});

export default axiosInstance;
