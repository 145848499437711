import React from "react";
import styled from "styled-components";
import TaskItem from "../Task/TaskItem"; // Assuming TaskItem is the individual task component

const TaskListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const TaskList = ({ tasks, listId, onUpdateTask, moveTask, onDeleteTask }) => {
  return (
    <TaskListContainer>
      {tasks.map((task, index) => (
        <TaskItem
          key={task.id}
          task={task}
          index={index}
          listId={listId}
          onUpdateTask={onUpdateTask}
          moveTask={moveTask}
          onDeleteTask={onDeleteTask}
        />
      ))}
    </TaskListContainer>
  );
};

export default TaskList;
