import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  getIdToken,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { auth } from "../utils/firebaseConfig";

const MagicLinkVerify = () => {
  const [message, setMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyMagicLink = async () => {
      let email = window.localStorage.getItem("emailForSignIn");

      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }

      if (!email) {
        setMessage("No email found for verification.");
        return;
      }

      if (isSignInWithEmailLink(auth, window.location.href)) {
        try {
          // Complete the sign-in and get the Firebase ID token
          await signInWithEmailLink(auth, email, window.location.href);
          const user = auth.currentUser;
          const idToken = await user.getIdToken(); // Get the Firebase ID token
          const baseURI =
            process.env.REACT_APP_TODOQUEUE_API_BASE_URL ||
            "https://todoqueue-server-2f08cc1055aa.herokuapp.com" ||
            "http://localhost:5000";

          console.log("--baseURI", baseURI);
          // Send the ID token to your server to receive a JWT
          const response = await axios.post(
            `${baseURI}/auth/verify-magic-link`,
            {
              email,
              token: idToken,
            }
          );

          const { token: jwtToken } = response.data;

          // Store the JWT in localStorage or sessionStorage
          window.localStorage.setItem("jwt", jwtToken);

          // Clean up and navigate to the dashboard
          window.localStorage.removeItem("emailForSignIn");
          setMessage("Login successful! Redirecting...");
          setTimeout(() => navigate("/"), 2000);
        } catch (error) {
          console.error("Error verifying magic link:", error);
          setMessage("Failed to verify magic link.");
        }
      }
    };

    verifyMagicLink();
  }, [location, navigate]);

  return <div>{message && <p>{message}</p>}</div>;
};

export default MagicLinkVerify;
