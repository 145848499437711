import React from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";
import { ItemTypes } from "../../utils/constants";

const TaskBoardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  background-color: white;
  transition: background-color 0.3s ease;
  min-height: 200px;
`;

function TaskBoard({ children, onDrop, disableDrop = false }) {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.TASK,
    drop: (item) => {
      if (!disableDrop) {
        onDrop(item);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <TaskBoardWrapper
      ref={drop}
      style={{ backgroundColor: isOver ? "#f0f0f0" : "white" }}
    >
      {children}
    </TaskBoardWrapper>
  );
}

export default TaskBoard;
