import { Navigate } from "react-router-dom";

function isAuthenticated() {
  const token = localStorage.getItem("jwt");
  // Check if the token exists and is not expired
  if (!token) return false;

  // Optionally, you can decode the token to check its expiry (or use a library like `jwt-decode`)
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return payload.exp > Date.now() / 1000; // Check if the token is still valid
  } catch (error) {
    return false;
  }
}

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
