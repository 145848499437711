import React, { useState, useRef, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import ReactMarkdown from "react-markdown";
import { format, differenceInHours, differenceInDays } from "date-fns";
import { ItemTypes } from "../../utils/constants";
import "./TaskItem.css";

// Mapping for status colors
const statusColors = {
  Complete: "#4caf50",
  "In Progress": "#ff9800",
  Backlog: "#f44336",
};

// Mapping for urgency levels
const urgencyMap = {
  0: "Critical",
  1: "High",
  2: "Medium",
  3: "Low",
  4: "Minor",
};

const urgencyColors = {
  0: "#f44336", // Red - Critical
  1: "#ff9800", // Orange - High
  2: "#ffeb3b", // Yellow - Medium
  3: "#8bc34a", // Light Green - Low
  4: "#4caf50", // Green - Minor
};

function TaskItem({ task, index, listId, moveTask, onUpdateTask }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [title, setTitle] = useState(task.title);
  const [description, setDescription] = useState(task.description);
  const [tags, setTags] = useState(task.tags);
  const [newTag, setNewTag] = useState("");
  const descriptionRef = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.TASK,
    item: { id: task.id, index, listId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.TASK,
    hover(draggedItem) {
      if (draggedItem.id !== task.id) {
        moveTask(draggedItem.index, index, draggedItem.listId, listId);
        draggedItem.index = index;
        draggedItem.listId = listId;
      }
    },
  });

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleEscKey = (event) => {
    if (event.key === "Escape" && isExpanded) {
      setIsExpanded(false);
    }
  };

  const handleDescriptionClick = () => {
    setIsEditingDescription(true);
  };

  const handleDescriptionBlur = () => {
    setIsEditingDescription(false);
    onUpdateTask(task.id, { title, description, tags });
  };

  const handleTitleBlur = () => {
    onUpdateTask(task.id, { title, description, tags });
  };

  const handleTagKeyPress = (event) => {
    if (event.key === "Enter" && newTag.trim()) {
      setTags([...tags, newTag.trim()]);
      setNewTag("");
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  useEffect(() => {
    if (isExpanded) {
      document.addEventListener("keydown", handleEscKey);
    } else {
      document.removeEventListener("keydown", handleEscKey);
    }

    return () => document.removeEventListener("keydown", handleEscKey);
  }, [isExpanded]);

  const getTimeRemaining = () => {
    const dueDate = new Date(task.expirationDate);
    const now = new Date();
    console.log(task.expirationDate, dueDate, now);

    const hoursRemaining = differenceInHours(dueDate, now);
    if (hoursRemaining < 24) {
      if (hoursRemaining === 0) {
        return `Due now`;
      }
      return `${hoursRemaining} hours left`;
    }

    const daysRemaining = differenceInDays(dueDate, now);
    return `${daysRemaining} days left`;
  };

  return (
    <div
      className={`sticky-note ${isExpanded ? "is-expanded" : ""}`}
      ref={(node) => drag(drop(node))}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <button className="expand-button" onClick={handleExpandToggle}>
        {isExpanded ? "✕" : "⇲"}
      </button>
      <div className="header-info">
        <div className="task-date">{getTimeRemaining()}</div>

        <div className="last-updated">
          <div>
            Last updated: {format(new Date(task.updatedAt), "MMM d, h:mm a")}
          </div>
        </div>
        <select
          className="task-urgency"
          value={task.urgency}
          onChange={(e) =>
            onUpdateTask(task.id, { urgency: Number(e.target.value) })
          }
          style={{
            backgroundColor: urgencyColors[task.urgency],
            fontSize: "0.8em",
          }}
        >
          {Object.keys(urgencyMap).map((level) => (
            <option
              key={level}
              value={level}
              style={{ backgroundColor: urgencyColors[level] }}
            >
              {urgencyMap[level]}
            </option>
          ))}
        </select>
      </div>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        onBlur={handleTitleBlur}
        className="task-title"
        onFocus={(e) => (e.target.style.WebkitLineClamp = "unset")}
      />
      {isEditingDescription ? (
        <textarea
          ref={descriptionRef}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onBlur={handleDescriptionBlur}
          autoFocus
        />
      ) : (
        <div className="markdown-description" onClick={handleDescriptionClick}>
          <ReactMarkdown>{description}</ReactMarkdown>
        </div>
      )}
      <div className="task-meta">
        <div className="task-tags">
          {tags.map((tag, i) => (
            <span
              key={i}
              className="task-tag"
              onClick={() => handleTagDelete(tag)}
            >
              {tag} ✕
            </span>
          ))}
          <input
            type="text"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            onKeyPress={handleTagKeyPress}
            placeholder="Add tag"
          />
        </div>
        <select
          className="task-status"
          value={task.status}
          onChange={(e) => onUpdateTask(task.id, { status: e.target.value })}
          style={{ backgroundColor: statusColors[task.status] }}
        >
          {Object.keys(statusColors).map((status) => (
            <option
              key={status}
              value={status}
              style={{ backgroundColor: statusColors[status] }}
            >
              {status}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default TaskItem;
