import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import TaskConverterInput from "./TaskConverterInput";

const StickyNote = styled.div`
  width: ${(props) =>
    props.isExpanded
      ? "calc(100vw - 80px)"
      : "400px"}; /* Twice the size of a regular task */
  height: ${(props) =>
    props.isExpanded
      ? "calc(100vh - 80px)"
      : "400px"}; /* Twice the size of a regular task */
  padding: 15px;
  margin: 10px;
  background-color: #ffef85;
  border-left: 8px solid #ff9800;
  border-radius: 5px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
  cursor: grab;
  transition: transform 0.2s ease, width 0.3s, height 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: ${(props) => (props.isExpanded ? "fixed" : "relative")};
  top: ${(props) => (props.isExpanded ? "10px" : "0")};
  left: ${(props) => (props.isExpanded ? "10px" : "0")};
  z-index: ${(props) => (props.isExpanded ? "1001" : "1")};
  &:active {
    cursor: grabbing;
  }
  h3 {
    font-weight: bold;
    margin-bottom: 5px;
  }
  input,
  textarea {
    width: 100%;
    background: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    resize: none;
  }
  textarea {
    flex: 1;
    margin-top: 10px;
  }
  .expand-button {
    position: absolute;
    top: 5px;
    left: 5px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    z-index: 1000;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const StatusDropdown = styled.select`
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f5f5f5;
  margin-left: 10px;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const UrgencyContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const UrgencyButton = styled.button`
  padding: 5px 10px;
  background-color: ${(props) => (props.selected ? "#28a745" : "#ccc")};
  color: ${(props) => (props.selected ? "white" : "black")};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.selected ? "#218838" : "#bbb")};
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const Tag = styled.div`
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TagInput = styled.input`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  width: auto;
  flex: 1;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

function TaskCreationModal({ onClose, onCreateTask }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Backlog");
  const [urgency, setUrgency] = useState(2);
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const modalRef = useRef();

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleAddTag = (e) => {
    if (e.key === "Enter" && newTag.trim()) {
      if (!tags.includes(newTag.trim())) {
        setTags((prevTags) => [...prevTags, newTag.trim()]);
      }
      setNewTag("");
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  const handleSaveAndClose = useCallback(() => {
    const trimmedTitle = title.trim();
    const trimmedDescription = description.trim();

    if (trimmedTitle === "" && trimmedDescription === "") {
      onClose(); // Do not save an empty task
      return;
    }

    const newTask = {
      id: uuidv4(),
      title: trimmedTitle,
      description: trimmedDescription,
      status,
      urgency,
      tags,
      createdAt: new Date().toLocaleString(),
      updatedAt: new Date().toLocaleString(),
    };

    onCreateTask(newTask);
    onClose();
  }, [title, description, status, urgency, tags, onClose, onCreateTask]);

  const handleEscKey = useCallback(
    (event) => {
      if (event.key === "Escape") {
        handleSaveAndClose();
      }
    },
    [handleSaveAndClose]
  );

  const handleClickOutside = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleSaveAndClose();
      }
    },
    [handleSaveAndClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscKey);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleEscKey, handleClickOutside]);

  // Handle data parsed from TaskConverterInput
  const handleConvertedTask = (parsedTask) => {
    setTitle(parsedTask.title);
    setDescription(parsedTask.description);
    setUrgency(parsedTask.urgency);
    setTags(parsedTask.tags);
  };

  return (
    <ModalOverlay>
      <StickyNote ref={modalRef} isExpanded={isExpanded}>
        <button className="expand-button" onClick={handleExpandToggle}>
          {isExpanded ? "✕" : "⇲"}
        </button>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title"
          style={{ fontWeight: "bold" }}
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        />
        <div
          style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
        >
          <label>Status:</label>
          <StatusDropdown
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="Backlog">Backlog</option>
            <option value="In Progress">In Progress</option>
            <option value="Complete">Complete</option>
          </StatusDropdown>
        </div>
        <UrgencyContainer>
          <label>Urgency:</label>
          {[0, 1, 2, 3, 4].map((level) => (
            <UrgencyButton
              key={level}
              selected={urgency === level}
              onClick={() => setUrgency(level)}
            >
              P{level} - {["Critical", "High", "Medium", "Low", "Minor"][level]}
            </UrgencyButton>
          ))}
        </UrgencyContainer>
        <TagContainer>
          {tags.map((tag) => (
            <Tag key={tag}>
              {tag}
              <button
                style={{
                  marginLeft: "5px",
                  background: "none",
                  border: "none",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => handleDeleteTag(tag)}
              >
                ✕
              </button>
            </Tag>
          ))}
          <TagInput
            type="text"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            onKeyDown={handleAddTag}
            placeholder="Add a tag and press Enter"
          />
        </TagContainer>
        <TaskConverterInput onConvert={handleConvertedTask} />
      </StickyNote>
    </ModalOverlay>
  );
}

export default TaskCreationModal;
