// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f0f4f8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

h2 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

input[type="email"] {
  width: 100%;
  padding: 0.75rem;
  margin: 1rem 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

p {
  font-size: 0.95rem;
  color: #666;
  margin-top: 1rem;
}

.message {
  margin-top: 1rem;
  font-size: 1rem;
  color: #28a745;
}
`, "",{"version":3,"sources":["webpack://./src/components/MagicLinkInput.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,SAAS;EACT,iCAAiC;EACjC,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,yCAAyC;EACzC,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB","sourcesContent":["/* styles.css */\nbody {\n  margin: 0;\n  font-family: \"Roboto\", sans-serif;\n  background-color: #f0f4f8;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.container {\n  background-color: #ffffff;\n  padding: 2rem;\n  border-radius: 8px;\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n  width: 100%;\n  max-width: 400px;\n  text-align: center;\n}\n\nh2 {\n  color: #333;\n  margin-bottom: 1rem;\n  font-size: 1.8rem;\n}\n\ninput[type=\"email\"] {\n  width: 100%;\n  padding: 0.75rem;\n  margin: 1rem 0;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  font-size: 1rem;\n}\n\nbutton {\n  width: 100%;\n  padding: 0.75rem;\n  background-color: #007bff;\n  color: white;\n  font-size: 1rem;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\nbutton:hover {\n  background-color: #0056b3;\n}\n\np {\n  font-size: 0.95rem;\n  color: #666;\n  margin-top: 1rem;\n}\n\n.message {\n  margin-top: 1rem;\n  font-size: 1rem;\n  color: #28a745;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
