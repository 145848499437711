import React from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";
import { ItemTypes } from "../../utils/constants";

const HorizontalListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  with: 100%;
  gap: 20px;
  padding: 10px;
  background-color: ${(props) => (props.isOver ? "#f0f0f0" : "white")};
  transition: background-color 0.3s ease;
  min-height: 100px;
`;

function HorizontalTaskList({ children, onDrop }) {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.TASK,
    drop: (item) => {
      onDrop(item);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <HorizontalListWrapper
      ref={drop}
      style={{ backgroundColor: isOver ? "#f0f0f0" : "white" }}
    >
      {children}
    </HorizontalListWrapper>
  );
}

export default HorizontalTaskList;
