import React, { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import "./MagicLinkInput.css";

const RequestMagicLink = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const baseURI =
        process.env.REACT_APP_TODOQUEUE_API_BASE_URL ||
        "https://todoqueue-server-2f08cc1055aa.herokuapp.com" ||
        "http://localhost:5000";
      console.log("--baseURI", baseURI);
      const response = await axios.post(`${baseURI}/auth/magic-link`, {
        email,
      });
      console.log(response.data);
      window.localStorage.setItem("emailForSignIn", email);
      setMessage("Magic link sent! Check your email.");
    } catch (error) {
      setMessage("Failed to send magic link. Please try again.");
    }
  };

  return (
    <motion.div
      className="container"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      <h2>Login with Magic Link</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Send Magic Link</button>
      </form>
      {message && <p className="message">{message}</p>}
    </motion.div>
  );
};

export default RequestMagicLink;
